<template>
  <Header />
  <div class="container">
    <h2>Manage Admins</h2>
    <div>
      <!-- <Dropdown v-model="selectedUser" :options="staffs" placeholder="Select a User" /> -->
      <div>
        <select v-model="selectedUser" class="p-dropdown p-component p-inputwrapper p-inputwrapper-filled p-inputwrapper-focus">
          <option value="Select Staff" disabled>Select Staff</option>
          <option v-for="staff in nonAdms" :key="staff.id" :value="staff.id">
            {{ staff.name }}
          </option>
        </select>
      </div>
      <Button class="p-mt-2 p-mb-2" label="Make Admin" type="submit" @click="promoteToAdm(selectedUser)" />
    </div>
    <div>
        <Toolbar class="p-mb-2" v-for="{id, name} in admins" :key="id">
            <template #left>
                <div class="font-black" >{{name}}</div>
            </template>

            <template #right>
                <Button icon="pi pi-times" class="p-button-danger" @click="updateStaff(id)" />
            </template>
        </Toolbar>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
// import Dropdown from 'primevue/dropdown';
import {ref} from 'vue';
import {useLoadAdmins, updateStaff, useLoadNonAdm, promoteToAdm} from '@/fbDomain'

export default {
  components: {Toolbar,Button,Header},
  setup() {
    const selectedUser = ref("Select Staff");
    const admins = useLoadAdmins()
    const nonAdms = useLoadNonAdm()
    
    return {admins, updateStaff, selectedUser, nonAdms, promoteToAdm}
  },
}
</script>

<style>
.font-black{
    color: #000;
}
.no-decor{
    text-decoration: none;
}
</style>

